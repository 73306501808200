import { enqueueSnackbar } from 'notistack';
import refreshTicket from '../controllers/booking/refreshTicket';

export default function useFlight() {
    async function refreshBooking(id) {
        
        const res = await refreshTicket(id);
        if(res.return) {
            enqueueSnackbar('Refreshed',{variant: 'success'})
        } else enqueueSnackbar(res.msg,{variant: 'error'})

        return res
    }
    
  return {
    refreshBooking,
  }
}
