import { formatMoney } from "../../../features/utils/formatMoney";
import Checkbox from "../../form/Checkbox";
import subscribe from "../../../controllers/subscription/subscribe";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Modal, Switch } from "@mui/material";
import Button1 from "../../form/Button1";
import changeSubscription from "../../../controllers/subscription/changeSubscription";
import PaystackButton from "../../Paystack/PaystackButton";
import { generateRef } from "../../../utils/generateRef";
import addCard from "../../../controllers/settings/finance/addCard";

export function Summary({ plans, plan, user, callback, subscriptionPlan, isAnnual }) {
  const data = [
    // {label: 'Miles Subscription',price: 50000,currency: 'ngn',description: 'Starter plan, 5 agents'},
    // {label: 'Subtotal',price: 50000,currency: 'ngn',description: 'Starter plan, 5 agents'},
    // {label: 'Your Savings',price: 50000,currency: 'ngn',description: 'Starter plan, 5 agents'},
  ];
  const { enqueueSnackbar } = useSnackbar();
  const [paymentUrl, setPaymentUrl] = useState();
  const [loading, setLoading] = useState(false);
  const [agreement, setAggrement] = useState(false);
  const [yearly, setYearly] = useState(false);

  // useEffect(() => {
  //   setYearly(isAnnual)
  // },[isAnnual])
  
  const handleAddCard = async (reference) => {
    const res = await addCard(reference);
    if (res.return) {
      enqueueSnackbar("Card Added Successfully", { variant: "success" });
    } else enqueueSnackbar(res?.msg, { variant: "error" });
  };

  const freePlan =
    !subscriptionPlan?.plan || subscriptionPlan?.plan?.mode === "FREE";

  const selected = (plans || [])?.find((obj) => obj._id === plan);
  console.log(selected, plans, plan,subscriptionPlan);

  async function handleSubscription() {
    const payload = {
      paymentMode: "Card",
      planId: plan,
      callbackUrl: window.location.href,
      isAnnual: yearly ? true : false,
    };
    if (payload.callbackUrl?.toString()?.includes("?")) {
      payload.callbackUrl += "&success=true";
    } else payload.callbackUrl += "?success=true";

    setLoading(true);

    let res = {
      return: 0,
      msg: "Something went wrong on our end! (0xSROCR). Please contact support",
    };
    if (subscriptionPlan?.plan?._id && subscriptionPlan?.plan?.mode !== 'FREE') res = await changeSubscription(payload);
    else res = await subscribe(payload);
    setLoading(false);
    if (res.return) {
      console.log(res.data);
      const url = res?.data?.initiate?.data?.authorization_url || res?.data?.upgrade?.data?.data?.authorization_url;
      // setPaymentUrl(res?.data?.data?.authorization_url);
      // const width = 600; // Desired width of the popup window
      // const height = 400; // Desired height of the popup window

      // const left = window.screen.width / 2 - width / 2;
      // const top = window.screen.height / 2 - height / 2;

      // const options = `width=${width}, height=${height}, left=${left}, top=${top}`;

      if (url) {
        setPaymentUrl(url);
        // const popup = window.open(url,'popupWindow')
        // const checkPopupClosed = setInterval(() => {
        //   if (popup?.closed) {
        //     clearInterval(checkPopupClosed);
        //     setPaymentUrl(undefined)
        //     callback && callback();
        //   }
        // }, 1000);
        window.location.href = url;
      } else {
        enqueueSnackbar(res?.msg, { variant: "success" });
        callback && callback();
      }
    } else enqueueSnackbar(res.msg, { variant: "error" });
  }

  return (
    <div className="flex flex-col gap-4 bg-secondary !p-5">
      <h5>Plan Details</h5>
      <div>
        <h6>Billing cycle</h6>
        <div className="flex gap-4 items-center">
          Monthly <Switch onChange={(ev) => setYearly(ev?.target?.checked)} />{" "}
          Annually
        </div>
      </div>
      <div>
        {data?.map((obj, i) => (
          <div
            key={i}
            className={`flex justify-between py-3 my-4 ${
              i !== 0 ? "border-t border-primary/25" : ""
            }`}
          >
            <div>
              <h6>{obj.label}</h6>
              <p>{obj.description}</p>
            </div>
            <div>
              <h6>
                {formatMoney(obj.price * (yearly ? 12 : 1))}
                {yearly ? "/yr" : "/mo"}
              </h6>
              {!yearly ? <p>x12 months</p> : null}
            </div>
          </div>
        ))}
        {yearly ? (
          <div className="flex gap-4 justify-between mb-4">
            <h6>Miles subscription</h6>
            <div className="text-end">
              <h6>{formatMoney(selected?.price || 0)}/mo</h6>
              <p>x12 months</p>
            </div>
          </div>
        ) : null}
        <div className="flex gap-4 justify-between">
          <h6>Subtotal</h6>
          <div className="text-end">
            <h6>{formatMoney(selected?.price * (yearly ? 12 : 1) || 0)}</h6>
            <p>+ taxes and fees</p>
          </div>
        </div>
      </div>

      {/* {freePlan ? 
        <div className='flex flex-col gap-6'>
          <b>Add Payment Method</b>
          <div className='bg-secondary md:!p-5 flex flex-col gap-4'>
            <span className="self-start">
              <PaystackButton
                config={{
                  amount: 5000,
                  reference: generateRef("AC-"),
                  email: user?.email,
                  channels: ['card'],
                }}
                onSuccess={(reference) => {
                  handleAddCard(reference?.reference);
                }}
              >
                <Button1>Add Payment Card</Button1>
              </PaystackButton>
            </span>
            <p>
              We will charge your card a ₦50 fee only to setup your payment method. This will be refunded back to your card.
            </p>
          </div>
        </div>
        :null} */}

      <Checkbox
        labelClassName="px-4"
        onChange={(ev) => setAggrement(ev?.target?.checked)}
      >
        I agree to the Main Services Agreement, Privacy Policy, terms and
        conditions for any applicable add-ons. I understand my subscription
        starts today.
      </Checkbox>

      <Button1
        disabled={!agreement}
        loading={loading}
        className=""
        onClick={handleSubscription}
      >
        Confirm Subscription
      </Button1>
      <Modal
        open={Boolean(paymentUrl)}
        onClose={() => setPaymentUrl(undefined)}
      >
        <div className="h-full max-w-[100%] flex flex-col text-secondary bg-primary/80 items-center justify-center overflow-y-scroll md:overflow-y-hidden">
          {/* <iframe src={paymentUrl} title="Payment" className="w-full h-full" /> */}
          <div className="text-center">
            <div className="text-2xl">Waiting for payment</div>
            <p className="text-secondary">
              Close the payment window to continue
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
}
