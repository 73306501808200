import React, { useEffect, useState } from "react";
import GettingStartedFooter from "../../components/gettingStarted/GettingStartedFooter";
import { useNavigate } from "react-router-dom";
import GettingStartedStepTitle from "../../components/gettingStarted/GettingStartedStepTitle";
import GettingStartedLoadingModal from "../../components/modal/GettingStartedLoadingModal";
import { useDispatch } from "react-redux";
import { setGettingStartedLoadingModal } from "../../redux/reducers/modalSlice";
import { useSnackbar } from "notistack";
import updateProfile from "../../controllers/user/updateProfile";
import { setUser } from "../../redux/reducers/userSlice";
import getPlans from "../../controllers/subscription/getPlans";
import { formatMoney } from "../../features/utils/formatMoney";
import { URLencode } from "../../utils/url";

function GettingStartedPricing() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [pricingPlans, setPricingPlans] = useState([]);

  const loadData = async () => {
    dispatch(setGettingStartedLoadingModal(true));
    const res = await getPlans();
    if (res.return) {
      setPricingPlans(res.data?.data);
    }
    dispatch(setGettingStartedLoadingModal(false));
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleContinue = async () => {
    // dispatch(setGettingStartedLoadingModal(true));

    // const formData = new FormData();
    // formData.append("guideStep", "done");

    // const res = await updateProfile(formData);
    // dispatch(setGettingStartedLoadingModal(false));

    // if (res.return) {
    //   if (res?.data) dispatch(setUser(res.data));
    //   navigate("/gettingStarted/congratulations");
    // } else enqueueSnackbar(res.msg || "Error", { variant: "error" });
    navigate("/");
  };

  const handleUpgrade = async (index) => {
    let plans = [...pricingPlans];

    let parameters = {
      plan: plans[index]?._id,
      guide: true,
    };
    navigate(`/gettingStarted/subscribe?${URLencode(parameters)}`);
    // dispatch(setGettingStartedLoadingModal(true));

    // const formData = new FormData();
    // formData.append("guideStep", "done");

    // const res = await updateProfile(formData);
    // dispatch(setGettingStartedLoadingModal(false));

    // if (res.return) {
    //   if (res?.data) dispatch(setUser(res.data));
    // } else enqueueSnackbar(res.msg || "Error", { variant: "error" });
  };

  return (
    <div className="flex flex-col flex-1 w-full items-center">
      <GettingStartedLoadingModal />
      <div className="flex w-full flex-col py-10 px-4 lg:px-10 flex-1 max-w-7xl uncollapse1">
        <GettingStartedStepTitle active={true} visible title="Choose a plan" />
        <div className="w-full flex overflow-scroll gap-4 scroll-hide pb-4 ">
          {pricingPlans.map((pricing, index) => (
            <div
              className="w-72 lg:w-96 shadow-md border border-gray rounded-b-lg py-6 flex flex-col flex-1 flex-shrink-0"
              key={index}
            >
              <div className="w-64 lg:w-72 px-4 flex flex-col flex-shrink-0">
                <span className="font-bold text-xl lg:text-3xl">
                  {pricing?.name}
                </span>
                <p className="text-xs lg:text-sm text-gray-300 h-16">
                  {pricing?.description}
                </p>
              </div>
              {/* {index === 3 ? (
                <div className="w-full h-28 flex justify-center flex-col bg-[#F6F5FC] px-4 ">
                  <span className="text-xs lg:text-sm">{pricing?.per}</span>
                  <span className="font-bold text-xl lg:text-3xl mr-1">
                    Contact Us
                  </span>
                </div>
              ) : (
                <div className="w-full h-28 flex justify-center flex-col bg-[#F0F6FC] px-4 ">
                  <div>
                    <span className="font-bold text-xl lg:text-3xl mr-1">
                      {pricing?.price}
                    </span>
                    <span className="text-xs lg:text-sm">per user/month</span>
                  </div>
                  <span className="text-xs lg:text-sm">
                    paid in Naira equivalent
                  </span>
                </div>
              )} */}

              <div className="w-full h-28 flex justify-center flex-col bg-[#F0F6FC] px-4 ">
                <div>
                  <span className="font-bold text-xl lg:text-3xl mr-1">
                    {formatMoney(pricing?.price)}
                  </span>
                </div>
                <span className="text-xs lg:text-sm">per user/month</span>
                {/* <span className="text-xs lg:text-sm">
                  paid in Naira equivalent
                </span> */}
              </div>

              <div className="w-full px-4 flex flex-1 flex-col">
                <span className="text-xs lg:text-sm text-gray-600 mb-2 mt-4">
                  Key features
                </span>
                {pricing?.features?.map((feature, idx) => (
                  <div
                    className="flex w-full items-center gap-2 my-1 text-xs lg:text-sm text-gray-600"
                    key={idx}
                  >
                    <img
                      src="/IconCheckmark.svg"
                      alt=""
                      className="w-5 h-5 object-contain"
                    />
                    <span>{feature}</span>
                  </div>
                ))}
              </div>
              <div className="flex flex-col flex-1"></div>
              <div className="w-full px-4 flex items-center justify-center mt-6">
                {index === 3 ? (
                  <button
                    className="w-full flex items-center justify-center h-12 rounded-md bg-[#6A59D1] text-white font bold "
                    onClick={() => handleUpgrade(index)}
                  >
                    Contact our sales team
                  </button>
                ) : (
                  <button
                    className="w-full flex items-center justify-center h-12 rounded-md bg-primary1 text-white font bold "
                    onClick={() => handleUpgrade(index)}
                  >
                    Get Started
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="flex flex-col flex-1"></div>
        <GettingStartedFooter
          onContinue={handleContinue}
          continueText={"Skip and subscribe later"}
          continueActive={true}
          onPrevious={() => navigate(-1)}
        />
      </div>
    </div>
  );
}

export default GettingStartedPricing;
