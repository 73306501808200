import PaystackButton from '../../Paystack/PaystackButton';
import { generateRef } from '../../../utils/generateRef';
import { useSnackbar } from 'notistack';
import addCard from '../../../controllers/settings/finance/addCard';
import { MenuItem } from "@mui/material";
import Button1 from '../../form/Button1';
import TextInput from '../../form/TextInput';


export function Planner({plans,plan,setPlan,user,subscriptionPlan,isAnnual}) {
    // const [selected,setSelected] = useState(plan || '')
    const {enqueueSnackbar} = useSnackbar();
  
    const handleAddCard = async (reference) => {
      const res = await addCard(reference);
      if(res.return) {
        enqueueSnackbar('Card Added Successfully',{variant: 'success'})
      } else enqueueSnackbar(res?.msg,{variant: 'error'})
    };
  
    const freePlan = !subscriptionPlan?.plan || (subscriptionPlan?.plan?.mode === 'FREE');
    const selected = (plans || [])?.find((obj) => obj._id === plan);

    return (
      <div className='flex flex-col gap-6'>
        <div className='bg-secondary md:!p-5 flex gap-4 items-center'>
          <div className='flex-1'>
            <b>{selected ? selected?.name+' Plan' : (subscriptionPlan?.plan?.name || '')+' Plan:'}</b>
          </div>
          <div className='flex-1'>
            <TextInput select label='Select a plan' noShrink value={plan} onChange={(ev) => setPlan(ev.target.value)} size='small' className='w-full'>
              {(plans||[])?.filter(obj => isAnnual ? obj.durationInDays === 30 : true)?.map((obj,i) => (
                <MenuItem key={i} value={obj?._id}>{obj.name}</MenuItem>
              ))}
            </TextInput>
          </div>
        </div>
        
        {/* {freePlan ? 
        <div className='flex flex-col gap-6'>
          <b>Add Payment Method</b>
          <div className='bg-secondary md:!p-5 flex flex-col gap-4'>
            <span className="self-start">
              <PaystackButton
                config={{
                  amount: 5000,
                  reference: generateRef("AC-"),
                  email: user?.email,
                  channels: ['card'],
                }}
                onSuccess={(reference) => {
                  handleAddCard(reference?.reference);
                }}
              >
                <Button1>Add Payment Card</Button1>
              </PaystackButton>
            </span>
            <p>
              We will charge your card a ₦50 fee only to setup your payment method. This will be refunded back to your card.
            </p>
          </div>
        </div>
        :null} */}
        <hr />

      </div>
    )
}
  