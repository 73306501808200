import React, { useEffect, useState } from "react";
import GuideCTA from "../../components/gettingStarted/GuideCTA";
import GettingStartedWelcomeSection from "../../components/gettingStarted/GettingStartedWelcomeSection";
import PendingTasks from "../Dashboard/PendingTasks";
import { useSelector } from "react-redux";
import { getTestLevel } from "../../utils/testLevel";
import { def } from "../../config";
import GettingStartedVideoModal from "../../components/modal/GettingStartedVideoModal";
import { cards } from "../../components/ProfileSurvey/New/ProfileStatusCheck";
import Card from "../../components/DIsplay/Card";
import { getsubDomain, isCustomerSite } from "../../utils/getsubDomain";
import Overview from "../Dashboard/Overview";
import { useLocation, useNavigate } from "react-router-dom";
import SalesModal from "../../components/sales/SalesModal";

function GettingStartedHome() {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user.userData);
  const subdomain = getsubDomain();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const redirect = searchParams.get('redirect')

  const [openSalesModal,setOpenSalesModal] = useState(true);
  
  const customer = isCustomerSite();

  const handleExplore = () => {
    navigate("/welcome/pricing");
  };

  useEffect(() => {
    if(customer)
      navigate(redirect || '/order/new/flight')
  },[])

  if(customer)
    return null;

  return !subdomain ? (
    <div className="w-full px-4 lg:px-10 py-10 flex flex-col flex-1 font-main bg-[#F8F8F8]">
      {/* <div className="w-[500px]">
        <SalesModal openSalesModal={openSalesModal} toggleSalesModal={() => setOpenSalesModal(false)} />
      </div> */}
      <GettingStartedVideoModal />
      <div className="w-full flex justify-between">
        <div>
          {user?.guideStep !== "done" && (
            <span className="font-bold text-xl lg:text-3xl">
              Getting started
            </span>
          )}
        </div>
        {user?.detail?.isVerified && (
          <button
            className="text-sm lg:text-base text-[#2DA771] bg-[#80EFDF]/60 rounded-lg px-4 py-2 "
            onClick={handleExplore}
          >
            Explore plans
          </button>
        )}
      </div>
      <GuideCTA />
      <GettingStartedWelcomeSection />
      <div className="mt-8"></div>
      <Overview />
      <PendingTasks />
      {user?.detail?.isVerified ? 
        <div className="flex gap-6 flex-wrap md:flex-nowrap">
          {cards.map((card, i) => (
            <Card
            obj={card}
            key={i}
            className={`duration-${(i + 1) * 2 * 100}`}
            />
          ))}
        </div>
      :null}
    </div>
  ) : (
    <div className="w-full px-4 lg:px-10 py-10 flex flex-col flex-1 font-main">
      <GettingStartedWelcomeSection />
    </div>
  );
}

export default GettingStartedHome;

const Classname = {
  container: "",
};
